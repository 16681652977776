import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/badge/style";
import _Badge from "vant/es/badge";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "core-js/modules/es.array.push.js";
import { planDetail } from '@/api/wxproject';
export default {
  components: {
    [_Cell.name]: _Cell,
    [_Badge.name]: _Badge,
    [_Empty.name]: _Empty,
    [_ActionSheet.name]: _ActionSheet
  },
  data() {
    return {
      itemdata: [
        // {name:"触点人数",cnt:2,color:'#52C41A'},
        // {name:"销量目标（元）",cnt:2000,color:'#3875C6'},
        // {name:"上月销量（元）",cnt:2000,color:'#3875C6'},
      ],
      itemdata1: [
        // {name:"预计活跃",cnt:8,color:'#3875C6'},
        // {name:"实际活跃",cnt:6,color:'#3875C6'},
        // {name:"活跃合格",cnt:6,color:'#3875C6'},
      ],
      actions: [
        // { name: '选项一' },
        // { name: '选项二' },
        // { name: '选项三', subname: '描述信息' },
      ],
      show: false,
      listData: [],
      pgdatalist: [],
      type1: '',
      status1: ''
    };
  },
  mounted() {
    document.title = '终端计划详情';
    this.type1 = this.$route.query.type1;
    this.status1 = this.$route.query.status1;
    this.getData();
  },
  methods: {
    actcnt(index) {
      if (index === 0 && this.$route.query.type === 'now') {
        this.show = !this.show;
      } else if (index === 2) {
        _Dialog.alert({
          title: '说明',
          message: '上月终端销量之和'
        });
      }
    },
    implementationDetail(item = {}) {
      const {
        userCode,
        dateStr,
        isToday,
        batchCode
      } = item || {};
      console.log(userCode);
      this.$router.push({
        path: isToday ? '/ImplementationDetail2' : '/ImplementationDetail',
        query: {
          batchCode,
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type1: this.$route.query.type1,
          status1: this.$route.query.status1,
          orgTerminalCode: this.$route.query.orgTerminalCode,
          userCode: userCode,
          planCode: this.$route.query.planCode,
          date: dateStr
        }
      });
    },
    goback() {
      if (this.$route.query.Terminal) {
        this.$router.push({
          path: '/Terminal',
          query: {
            projectCode: this.$route.query.projectCode,
            month: this.$route.query.month,
            type: this.$route.query.type,
            position: this.$route.query.position,
            status: this.$route.query.status,
            types: this.$route.query.types,
            province: this.$route.query.province,
            city: this.$route.query.city,
            area: this.$route.query.area
          }
        });
      }
      if (this.$route.query.ActivationDetail) {
        this.$router.push({
          path: '/ActivationDetail',
          query: {
            projectCode: this.$route.query.projectCode,
            month: this.$route.query.month,
            type: this.$route.query.type,
            activeName: this.$route.query.activeName,
            // position:this.$route.query.position,
            status: this.$route.query.status,
            types: this.$route.query.types,
            province: this.$route.query.province,
            city: this.$route.query.city,
            area: this.$route.query.area
          }
        });
      }

      // window.history.back()
    },

    getData() {
      planDetail({
        planCode: this.$route.query.planCode,
        type: this.$route.query.type
        // planCode:'PLANXPDDXAEKZCBK',
        // type:'now'
      }).then(res3 => {
        this.listData = res3.data.data;
        if (this.$route.query.status1 !== 'CANCELLED') {
          this.itemdata = [{
            name: '触点人数',
            cnt: res3.data.data.pgList.length,
            color: '#52C41A'
          }, {
            name: `总达成目标（${res3.data.data.planSales.slice(-1)}）`,
            cnt: res3.data.data.planSales.slice(0, -1),
            color: '#3875C6'
          }, {
            name: `上月达成（${res3.data.data.historicalSales.slice(-1)}）`,
            cnt: res3.data.data.historicalSales.slice(0, -1),
            color: '#3875C6'
          }];
          this.itemdata1 = [{
            name: '预计活跃',
            cnt: res3.data.data.planDays,
            color: '#3875C6'
          }, {
            name: '实际活跃',
            cnt: res3.data.data.attCount,
            color: '#3875C6'
          }, {
            name: '活跃合格',
            cnt: res3.data.data.attPassCount,
            color: '#3875C6'
          }];
          if (this.$route.query.type === 'now') {
            this.actions = res3.data.data.pgList;
            this.actions.map(res4 => {
              res4['subname'] = res4.time;
            });
          }
        } else {
          this.itemdata = [{
            name: '触点人数',
            cnt: res3.data.data.pgList.length,
            color: '#999'
          }, {
            name: `总达成目标（${res3.data.data.planSales.slice(-1)}）`,
            cnt: res3.data.data.planSales.slice(0, -1),
            color: '#999'
          }, {
            name: `上月达成（${res3.data.data.historicalSales.slice(-1)}）`,
            cnt: res3.data.data.historicalSales.slice(0, -1),
            color: '#999'
          }];
          this.itemdata1 = [{
            name: '预计活跃',
            cnt: '-',
            color: '#999'
          }, {
            name: '实际活跃',
            cnt: '-',
            color: '#999'
          }, {
            name: '活跃合格',
            cnt: '-',
            color: '#999'
          }];
        }
        this.pgdatalist = res3.data.data.pgAttList;
      });
    }
  }
};